#post-482, #post-728 {
	/*padding: 0;*/
	margin: 0;
  	background-size: 100%;
  	background-repeat: no-repeat;
	background:none;
}

.page-id-482 {
  background-image: url(https://gedser.info/wp-content/uploads/2018/02/sand-1473079_1920.jpg) !important;
}

.information {
  margin-top: 185px !important;
  margin-bottom: 5% !important;
  
  /*
    width: 70%;
	top: 0;
  margin-left: 20%;
	padding: 10px;
	color: #68520f;
	background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
  */
}

.rolldown-list {
  text-align: left;
  padding: 0;
  margin: 0;
  max-width: 100%;
  min-width: 70%; 
  /* padding-bottom: 200px; */
}

.rolldown-list li {
  padding: .5em;
  font-size: .95em;
  margin-bottom: .125em;
  display: block;
  list-style: none;
  text-transform: uppercase;
  visibility: hidden;
  animation: rolldown .7s 1;
  transform-origin: 50% 0;
  animation-fill-mode: forwards;
  transition: background-color .5s;
  /* border-bottom: 1px solid #795548; */
  
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
  text-align: center;
  flex-direction: column;
  
}
.rolldown-list li:last-child {
  border-bottom: none;
}

.rolldown-list li a {
  border: 1px dashed #ffd700d4;
  padding: 15px;
  box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.75);
  color: #3F51B5;
  background: #d0cc414f;
  /* transition: background .3s; */
  text-decoration: none;

    
  transition: box-shadow .5s, transform .5s, background .3s;
  transform:  perspective(500px) translateZ(0px);


}
.rolldown-list li a:hover {
  background: #00bcd4ba;
  box-shadow: 0px 0px 33px 0px rgba(0,0,0,0.85);
  transform:  perspective(500px) translateZ(40px);
}
.Page .page-content .rolldown-list li img {
  max-height: 200px;
  max-width: 50%;
  min-width: unset;
}


/* .rolldown-list li:hover {
  background-color: white !important;
} */

#myList {
  position: relative;
  width: 90%;
  margin: 0 auto;
}

#btnReload {
  position: relative;
  top: 0;
  left: 55%;
  transform: translateX(-50%) translateY(-150%);
  color: #333;
  background: #ccc;
  text-transform: uppercase;
  border: none;
  padding: .5em 1em;
}

#btnReload:hover {
  background: #ddd;
}

@keyframes rolldown {
  0% {
    visibility: visible;
    transform: rotateX(180deg) perspective(500px);
  }
  70% {
    visibility: visible;
    transform: rotateX(-20deg);
  }
  100% {
    visibility: visible;
    transform: rotateX(0deg);
  }
}


/* breakpoints */
/* phone only */
@media (max-width: 599px) {
  .information {
    margin-top: 100px !important;
  }
  .rolldown-list li {
  	font-size: .95em;
  }
}
/* tablet portrait only */
@media (min-width: 600px) and (max-width: 899px) {

}
/* tablet portrait up */
@media (min-width: 600px) {
  .rolldown-list li {
  	font-size: 1.1em;
  }
}
/* tablet landscape only */
@media (min-width: 900px) and (max-width: 1199px) {
}
/* tablet landscape up */
@media (min-width: 900px) {
  .rolldown-list li {
  	font-size: 1.15em;
  }
}
/* desktop only */
@media (min-width: 1200px) and (max-width: 1799px) {
}
/* desktop up */
@media (min-width: 1200px) {
  .rolldown-list li {
  	font-size: 1.3em;
  }
}
/* big desktop up */
@media (min-width: 1800px) {
}
