.cls-1{fill:#42454b;}
.cls-2{fill:#fcfcfc;}
.cls-3{fill:#5979c0;}

#banner_content{
	width: 100%;
	height: 130px;
	display: flex;
	justify-content: space-between;
	font-style: normal;
	position: absolute;
	font-weight: bold;
}
.open #banner_content{
	position: unset;
}
#banner_content img{
	align-self: center;
	max-height: 60px;
	margin: 0 25px 0 10px;
	pointer-events: none;
}
#rope svg {
	max-height: 130px;
	height: 130px;
	min-width: 75px;
	max-width: 33%;
}
#rope s img {
	box-shadow: 24px 18px 88px 0px rgba(0,0,0,0.75);
}
#banner_content span{
	text-align:center;
	font-size: 2.5em;
	line-height: 130px;
	color: #FFC107;
	padding: 0 10px;
}

#rope{
	/* width:42%; */
	height:0;
	top: 0;
	/* left: 11vw; */
	position: absolute;
	margin: 0;
	z-index: 1;
	transition:  transform 1s;
	transform-origin: top center;

	width:100%;
	left: 0;
	transform: scale(0.5);
	max-width: 600px;
	min-width: 290px;
}
#rope.open {
	z-index: 5;
	transform: scale(1);
}
#rope.open #banner {
	animation-play-state: paused;
	height: 70vh;
	overflow-y: scroll;
}
#rope.open #banner p {
	opacity: 1;
	pointer-events: auto;
	display: block;
}
#rope.open .rope-stretch {
	animation-play-state: paused;
}
#rope s{
	width:50%;
	display:inline-block;
	text-align: left;
}

#rope.noAnimation .rope-stretch, #rope.noAnimation #banner {
	animation: unset !important;
  }
.rope-stretch {
	transform-origin: top center;
	animation:stretch .87s infinite alternate;
	/* animation-delay: 1s; */
	animation-play-state: running;
	/* width: 14px; */
	height: 50px;
	pointer-events: none;
	margin: 0 20px;
}
#rope .right{
	text-align: right;
}
.open #banner {
	top: -6px;
	box-shadow: inset 0px 350px 150px -74px rgba(255, 207, 102, 0.671),
	inset 0px 155px 300px 200px rgba(255, 255, 255, 0.25);
}
#banner{
	transform-origin: top center;
	animation: push .87s infinite alternate;
	/* animation-delay: 1s; */
	transition: transform .5s, height .5s, box-shadow .5s;
	position: relative;
	left: 0%;
	top: 0;
	/* transform: translateX(-100%); */
	width:100%;
	height:130px;
	margin: 0 auto;
	margin-top: -5px;
	border-radius:15px;
	border:3px solid rgba(0,0,0,.18);
	border-top:3px solid rgba(255,255,255,.34);
	border-left:3px solid rgba(255,255,255,.34);
	box-shadow:inset 5px 5px 18px rgba(255,255,255,.34),
			   inset -5px -5px 18px rgba(0,0,0,.18),
			   24px 18px 88px 0px rgba(0,0,0,0.75);
	text-align:center;
	z-index:10;
	box-sizing:border-box;
	-moz-box-sizing:border-box;
	font-family: 'IM Fell English SC';
	/* font: italic 700 100% 'Roboto Condensed',sans-serif; */
	text-transform:uppercase;
	letter-spacing:0px;
	color:white;
	text-shadow: 2px 2px 10px black, -1px -1px 10px black;
	min-width: 150px;
}
#banner p{
	opacity: 0;
	transition: opacity 1s;
	pointer-events: none;
	display: none;
	text-transform: initial;
	text-align: left;
	margin-top: 0;
	margin-left: 15px;
	text-shadow: 2px 2px 10px white, -2px -2px 10px white;
	color: black;
}
#banner span{
	pointer-events: none;
	font-size: 190%;
}
  
@keyframes stretch{
	0%{
		transform: scaleY(1);
	}
	100%{
		transform: scaleY(1.5);
	}
}
@-webkit-keyframes stretch{
	0%{
		transform: scaleY(1);
	}
	100%{
		transform: scaleY(1.5);
	}
}
  
@keyframes push{
	0%{
		transform: translateY(0);
	}
	100%{
		transform: translateY(25px);
	}
}
@-webkit-keyframes push{
	0%{
		transform: translateY(0);
	}
	100%{
		transform: translateY(25px);
	}
}

/* phone only */
@media (max-width: 599px) {
	#banner {
		font-size: 140%;
	}
}

/* tablet portrait up */
@media (min-width: 600px) {
	#banner {
		font-size: 180%;
	}
}

/* tablet landscape only */
/* @media (min-width: 900px) and (max-width: 1199px) {
	#banner {
		font-size: 100%;
	}
} */

/* tablet landscape up */
@media (min-width: 900px) {
	/* #rope {
		width:42%;
		left: 11vw;
		transform: unset;
	} */
}