@-webkit-keyframes boat-start {
	0% {
		-webkit-transform: none;
		transform: none;
	}
	50% {
		-webkit-transform: translateY(4px);
		transform: translateY(4px);
	}
	100% {
		-webkit-transform: none;
		transform: none;
	}
}
@keyframes boat-start {
	0% {
		transform: none;
	}
	50% {
		transform: translateY(4px);
	}
	100% {
		transform: none;
	}
}
.sailboat {
	bottom: 5%;
	/* display: flex;
	justify-content: center; */
	position: fixed;
	width: 30%;
	height: 50%;
	-webkit-animation: boat-start 1s infinite 4s, sail 4s 1;
	animation: boat-start 1s infinite 4s, sail 4s 1;
	-o-animation: boat-start 1s infinite 4s, sail 4s 1;
	-moz-animation: boat-start 1s infinite 4s, sail 4s 1;
	z-index: 2;
	-webkit-transition: top 0.5s ease-out, transform 0.5s;
	transition: top 0.5s ease-out, transform 0.5s;
	cursor: pointer;
}

.sb_container {
	margin-left: 19%;
}

.sailboat:first-child { left: 10%;}
.sailboat:nth-child(2) { right: 10%; /*animation-delay: 0.2s;*/  animation: boat-start 1.3s infinite 4.2s, sail 4s 1; }

@keyframes sail {
	from {bottom: 44%; transform: rotateY(80deg) scale(0.5); -webkit-transform: rotateY(80deg) scale(0.5);}
	to {bottom: 5%; transform: rotateY(0deg) scale(1); -webkit-transform: rotateY(0deg) scale(1);}
}

.sailboat img {
	/* max-width: none; */
	/* height: 100%; */
	height: 50%;
	left: 50%;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: 40%;
	/* transform: translateX(-50%); */
}

@media only screen and (max-width: 500px) {
	.sailboat {
		justify-content: center;
	}
}





/* Title */
.center{
	position: absolute;
	/* top: -50%; */
	top: 30%;
	left: 50%;
	transform: translate(-50%, -50%);
  }
  .sailboat ul{
	display: flex;
	padding: 0;
  }
  .sailboat ul li{
   list-style: none;
	color: #fff;
	font-size: 1.5em;
	font-weight: bold;
	text-transform: uppercase;
	opacity: 0;
	/*animation: smoke 2s linear backwards 1;*/
  }
  .sailboat ul:hover li{
	animation: smoke 2s linear backwards 1;
	opacity: 1;
  }
  
  @keyframes smoke{
	0%{
	  transform: rotate(-145deg) translateY(-200px);
	  opacity: 0.0;
	  filter: blur(20px);
	}
	100%{
	  transform: rotate(0deg) translateY(0px);
	  opacity: 1;
	  filter: blur(1px);
	}
  }
  
  ul li:nth-child(1){animation-delay: 0s;}
  ul li:nth-child(2){animation-delay: 0.4s;}
  ul li:nth-child(3){animation-delay: 0.8s;}
  ul li:nth-child(4){animation-delay: 1.2s;}
  ul li:nth-child(5){animation-delay: 1.6s;}
  