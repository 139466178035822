.Page .page-content div.arrowLinks {
	margin-top: 15px;
	overflow: visible;
}
.Page .page-content div.arrowLinks > a > img {
	min-width: 20vw;
	width: 90%;
	transition: opacity .1s;
	border-radius: 0;
}
.Page .page-content div.arrowLinks > a > img:hover {
	opacity: .8;
}