#gradient {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	/* background: #836997; */
	text-align: left;
	padding-bottom: 100px;
	margin-top: 120px;
	padding-right: 15px;
	padding-left: 15px;
	margin-left: 25px;
	overflow-y: scroll;
	border-radius: 25px 0 0 0;
	box-shadow: 5px 5px 65px 10px rgba(0,0,0,1);
	transition: transform .5s;

	background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
	background-size: 400% 400%;
	background-position: 0% 50%;	
	-webkit-animation: Gradient 15s ease infinite;
	-moz-animation: Gradient 15s ease infinite;
	animation: Gradient 15s ease infinite;
	animation-delay: .6s;
}
#gradient.hidden {
	transform: translateX(100%);
}

@-webkit-keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@-moz-keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

/* @media (min-width: 900px) {
	#gradient {
	  margin-top: 220px;
	}
  } */