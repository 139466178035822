
  @import url('https://fonts.googleapis.com/css?family=IM+Fell+English+SC&display=swap');
  html {
    overflow: hidden;
    min-width: 100vw;
    min-height: 100vh;
  }
  
  body {
    margin: 0;
    padding: 0;
    font-family: IM Fell English SC;
    min-height: 100vh;
  }
  
  #root {
    min-height: 100vh;
    max-width: 100vw;
    background: #a9d9fe;
  }
  .App {
    text-align: center;
    overflow-x: hidden;
    min-height: 100vh;
    max-width: 100vw;
  }
  .Page {
    height: 100vh;
    width: 100vw;
    background: #b9b8b3;
    background-image: url('./sand_fein.jpg');
    background-size: cover;
    overflow: hidden;
  }
  .Page.Sprogvalg {
    background: #a9d9fe;
  }
  .Page.Kort {
    /* background: #a9d9fe; */
    overflow-x: hidden;
  }
  .Page.Sevaerdighed .page-content > div {
    overflow: visible;
  }
  .Page.Admin .page-content {
    position: absolute;
    padding-top: 60px;
    padding-bottom: 50px;
    padding-left: 15px;
    padding-right: 15px;
    height: 100%;
    top: 0;
    filter: unset;
  }
  .Page .page-content {
    box-shadow: inset 70px 60px 85px -70px rgba(0,0,0,1);
    background: #a9d9fe;
    /* width: calc(100% - 10vw - 15px);
    height: calc(100vh - 160px); */
    /* width: calc(100% - 40px); */
    width: 100%;
    /* height: 100%; */
    height: calc(100% - 160px);
    padding-top: 110px;
    padding-bottom: 50px;
    background-image: url('./sand_fein.jpg');
    background-size: cover;
    overflow: auto;
    transition: filter 1s, box-shadow 1s, opacity .13s, transform .43s;
    position: fixed;
    
    /* padding-left: 40px; */
    /* padding-left: 10vw; */
    padding-right: 15px;
    filter: brightness(100%);
    opacity: 1;
    transform-origin: left center;
  
    overflow-x: hidden;
    
  }
  .Page .page-content strong {
      font-weight: bold;
  }
  
  .Page.Quiz .page-content {
    padding-bottom: 100px;
    /* padding-left: 16vw; */
    /* height: calc(100vh - 210px);
    width: calc(100% - 16vw - 15px); */
  }
  .GedserQuiz {
    margin: 15px;
    /* padding-bottom: 180px; */
  }
  .react-quiz-container h2 {
    margin-bottom: 0;
    margin-top: 10px;
  }
  .Page.Sevaerdighed .page-content {
    padding-bottom: 100px;
    /* height: calc(100vh - 210px); */
    height: 100%;
  }
  
  .Page .page-content.hidden {
    opacity: .1;
    transform: rotateY(-45deg) scaleX(.75);
  }
  
  .Page .page-content::-webkit-scrollbar-track, #gradient::-webkit-scrollbar-track, #rope.open #banner::-webkit-scrollbar-track
  {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;
  
      width: 6px;
      background-color: #F5F5F5;
  
      background-color: #d1d0ce;
  }
  
  .Page .page-content::-webkit-scrollbar, #gradient::-webkit-scrollbar, #rope.open #banner::-webkit-scrollbar
  {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;
  
      width: 6px;
      background-color: #F5F5F5;
  
      background-color: #d1d0ce;
  }
  
  .Page .page-content::-webkit-scrollbar-thumb, #gradient::-webkit-scrollbar-thumb, #rope.open #banner::-webkit-scrollbar-thumb
  {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;
  
      width: 6px;
      background-color: #F5F5F5;
  
      background-color: #ad8d4d;
  }
  .Page.Admin .page-content img {
    border-radius: unset;
    /* transition: unset; */
    /* transform: unset; */
  }
  .Page.Admin .page-content img:hover {
    box-shadow: unset;
    transform: unset;
    position: unset;
    z-index: unset;
  }
  .Page .page-content img {
    border-radius: 0 80px 0;
    transition: box-shadow .5s, transform .5s, width .5s;
    transform:  perspective(500px) translateZ(0px);
  }
  .Page.Galleri .page-content img, .Page.KunstKultur .page-content img, .Page.Overnatninger .page-content img {
    border-radius: unset;
    min-width: unset;
  }
  .Page.Sevaerdigheder .page-content img {
    border-radius: unset;
  }
  .Page.Sevaerdighed .page-content img {
    max-width: 60%;
    max-height: 80vh;
    width: auto;
    height: auto;
  }
  .Page.Kort .page-content img {
    width: unset;
    border-radius: unset !important;
  }
  .slick-slider *:focus {
    outline: none;
  }
  .slick-slider img {
    /* transition: width .5s, margin .3s !important; */
    max-height: 100vh;
  }
  .slick-slider img:hover {
    transform: unset !important;
  }
  .slick-list {
    /* overflow: visible !important; */
    max-width: 100% ;
    /* overflow: hidden; */
  }
  .slick-prev {
    left: 10px !important;
    z-index: 5;
  }
  .slick-prev:before {
    text-shadow: 2px 2px 10px black, -1px -1px 10px black;
    color: #ffbf00 !important;
  }
  .slick-next{
    right: 10px !important;
    z-index: 5;
  }
  .slick-next:before {
    text-shadow: 2px 2px 10px black, -1px -1px 10px black;
    color: #ffbf00 !important;
  }
  
  
  .Page.KunstKultur .page-content img, .Page.MadDrikke .page-content img {
    /* width: 60%; */
    border: 1px solid #73694a;
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.75);
    transition: box-shadow 1s, transform 1s;
    transform:  perspective(500px) translateZ(0px);
    max-height: 100vh;
    max-width: 100%;
  }
  .Page.KunstKultur .page-content img.custom-btn {
    transform: perspective(500px) translateZ(40px);
    box-shadow: 0px 0px 33px 0px rgba(0,0,0,0.85);
  }
  .Page.KunstKultur .page-content img.custom-btn:hover {
    transform: perspective(500px) translateZ(30px);
    box-shadow: 0px 0px 23px 0px rgba(0,0,0,0.85);
  }
  .Page.KunstKultur .page-content img.custom-btn:active {
    transform: perspective(500px) translateZ(0px);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.85);
  }
  .Page .page-content img:hover {
    box-shadow: 0px 0px 33px 0px rgba(0,0,0,0.85);
    transform: perspective(500px) translateZ(60px);
    position: relative;
    z-index: 5;
  }
  .Page.Sevaerdigheder .page-content .rolldown-list img:hover {
    transform: perspective(0) translateZ(0);
    box-shadow: unset;
  }
  .Page .page-content div.arrowLinks > a > img {
    transition: transform .3s, opacity .1s !important;
  }
  .Page .page-content div.arrowLinks > a > img:hover {
    box-shadow:unset;
  }
  .Page .page-content div.arrowLinks > a:nth-of-type(2) > img:hover {
    box-shadow:unset;
    transform:  perspective(500px) translateZ(40px) scaleX(-1) !important;  
  }
  .galleri {
    padding: 0 15px;
  }
  /* .react-photo-gallery--gallery > div {
    display: grid !important;
    grid-template-columns: 40vw 40vw;
    grid-auto-rows: 40vw;
    align-items: center;
  }
  .react-photo-gallery--gallery > div > img {
    width: 100%;
  } */
  
  @keyframes dropIn {
    0%   { transform: perspective(500px)  translateZ(100px); }
    100% { transform: perspective(500px)  translateZ(0px);}
  }
  .page-content.pageFlip {
    transform: perspective(500px) rotateY(90deg);
  }
  .page-content.pageLift {
    /* transform: perspective(500px) rotateY(35deg); */
    transform: perspective(660px) rotateY(95deg);
  }
  
  .Page .page-content.blur {
    box-shadow: inset 105px -23px 165px -70px rgba(0,0,0,.8);
    filter: brightness(40%);
  }
  .Page.Kort .page-content {
    background: unset;
  }
  .Page h1 {
    text-align: center;
    font-family: 'IM Fell English SC';
    font-style: normal;
    font-size: 2em;
    color: #2f6bb1;
    text-shadow: 2px 2px 10px white, -1px -1px 10px white;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: normal;
  }
  .Page h2 {
    color: #360500;
  }
  
  .noText {
    line-height: 0; 
    font-size: 0;
    color: transparent; 
  }
  
  .Page .page-content a {
    word-wrap: break-word;
  }
  .Page .page-content .custom-btn {
      max-width: 200px!important;
    margin-bottom: 10px;
    transition: all .2s;
  }
  .Page .page-content img.custom-btn {
    border: none;
    border-radius: 5vw;
  }
  .Page .page-content .custom-btn:hover {
      filter: contrast(70%);
  }
  
  .youtube {
    /* width: 100%;
    max-width: 1000px; */
    width: 80vw;
    height: 45vw;
    max-width: 845px;
    max-height: 475px;
  }
  
  .noAnimation {
    animation: unset !important;
  }

  /* Slider */
  .metaslider > div {
    position: relative;
    overflow: hidden;
    margin: 20px auto 0 auto;
    border-radius: 4px;
  }
  
  .metaslider > div ul {
    position: relative;
    margin: 0;
    padding: 0;
    height: 200px;
    list-style: none;
  }
  
  .metaslider > div ul li {
    position: relative;
    display: block;
    float: left;
    margin: 0;
    padding: 0;
    width: 300px;
    height: 200px;
    background: #ccc;
    text-align: center;
    line-height: 200px;
  }
  
  .Impressum article h4 {
    margin-bottom: 5px;
    text-decoration: underline;
  }
  .Impressum article p {
    margin: 0;
  }

  hr {
    overflow: visible;
    height: 30px;
    border-style: solid;
    border-color: black;
    border-width: 1px 0 0 0;
    border-radius: 20px;
    margin: 50px auto 5px auto;
    width: 90%;
  }
  hr:before {
    height: 30px;
    border-style: solid;
    border-color: black;
    border-width: 0 0 1px 0;
    border-radius: 20px;
    margin-top: -31px;
    content: '';
    display: block;
  }
  
  
  /* breakpoints */
  /* phone only */
  @media (max-width: 599px) {
  }
  /* tablet portrait only */
  @media (min-width: 600px) and (max-width: 899px) {
    .Page .page-content {
        font-size: 1em;
    }
  
  }
  /* tablet portrait up */
  @media (min-width: 600px) {
    .Page .page-content {
        font-size: 1.5em;
    }
  }
  /* tablet landscape only */
  @media (min-width: 900px) and (max-width: 1199px) {
  }
  /* tablet landscape up */
  @media (min-width: 900px) {
    .Page .page-content {
      /* padding-top: 220px; */
        font-size: 1.8em;
      /* height: calc(100vh - 220px); */
    }
    /* .Page.Sevaerdighed .page-content {
      height: calc(100vh - 270px);
    } */
    .Page.Quiz .page-content {
      padding-bottom: 50px;
      /* padding-left: 10vw; */
      /* height: calc(100vh - 270px);
      width: calc(100% - 10vw - 15px); */
    }
  }
  /* desktop only */
  @media (min-width: 1200px) and (max-width: 1799px) {
  }
  /* desktop up */
  @media (min-width: 1200px) {
    .Page .page-content {
        font-size: 2em;
    }
  }
  /* big desktop up */
  @media (min-width: 1800px) {
  }
  