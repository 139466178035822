.map-wrapper {
position: absolute;
width: 100%;
height: 100%;
overflow: hidden;
}
.CustomMap {
position: absolute;
width: 100%;
max-width: 100%;
max-height: 100%;
top: calc(50vh - 100vw / 2);
transform: scale(1) translate(-11px, 30px);
}

#gedser_map {
	transition: width 0.05s linear, transform .5s ease-in-out;
	display: block;
	max-width: 100%;
	margin: auto;
	-webkit-transform: translate(0px, 0px);
			transform: translate(0px, 0px);
	border: 1px inset #edebe9;
	cursor: -webkit-grab;
	box-shadow: 0px 0px 33px 0px rgba(0,0,0,0.85);
}		

#gedser_map:hover {
	transform: unset;
}