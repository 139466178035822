.Page.KunstKultur .page-content .BusinessCard {
	position: relative;
	width: 70%;
	height: auto;
	margin: 0 auto;
	/* width: 1016px;
	height: 670px; */
	/* transform: scale(.5);
	transform-origin: left center; */
}
.Page.KunstKultur .page-content .BusinessCard img {
	transition: transform 1s;
	transform: perspective(1100px);
	width: unset;
	border: none;
	border-radius: unset;
}
.Page.KunstKultur .page-content .BusinessCard .bg {
	position: relative;
	z-index: 0;
	max-width: 100%;
	width: 100%;
	/* height: 100%; */
	transition: box-shadow 1.1s;
	box-shadow: inset -700px 0px 0px 0px rgba(0,0,0,.5),
				inset 700px 0px 0px 0px rgba(0,0,0,.5),
				0px 0px 20px 0px rgba(0,0,0,1),
				0px 0px 20px 0px rgba(0,0,0,1);
				
	background-size: cover;
	background-position: center;
	transform:  scale(1);	
	filter: sepia(20%);
}
.Page.KunstKultur .page-content .BusinessCard .left_side {
	transform-origin: left center;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 53.93%;
}
.Page.KunstKultur .page-content .BusinessCard .right_side {
	transform-origin: right center;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
	width: 46.07%;
	
}
.Page.KunstKultur .page-content .BusinessCard:hover .bg {
	box-shadow: inset 0px 0px 0px 0px rgba(0,0,0,1),
				inset 0px 0px 0px 0px rgba(0,0,0,1),
				-3px 0px 10px 0px rgba(0,0,0,1),
				3px 0px 10px 0px rgba(0,0,0,1);
}
.Page.KunstKultur .page-content .BusinessCard:hover .right_side {
	transform: rotateY(90deg) scale(1);
}
.Page.KunstKultur .page-content .BusinessCard:hover .left_side {
	transform: rotateY(-90deg) scale(1);
}