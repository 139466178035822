#vertical-nav {
	position: fixed;
	height: calc(100vh - 130px);
	width: 170px;
	left: 0;
	bottom: 0;
	padding: 0;
	z-index: 2;
	transform: translateX(-100%);
	transition: all 1s;
	min-width: 170px;
	list-style-type: none;
	text-align: left;
}
/* #vertical-nav {
	position: fixed;
	height: 85vh;
	width: 170px;
	left: 0;
	bottom: 0;
	padding: 0;
	z-index: 2;
	transform: translateX(-100%);
	transition: all 1s;
	min-width: 170px;
	list-style-type: none;
	text-align: left;
  } */

#vertical-nav li a {
	opacity: 0;
}
#vertical-nav.show {
	transform: translateX(-50%);
}
#vertical-nav.hide {
	transform: translateX(-100%);
}
#vertical-nav.hide, #vertical-nav.hide *, #vertical-nav.hide *:hover {
	pointer-events: none;
}
#waves-menu-btn:after {
    content: '';
    width: 100%;
    height: 100%;
    background: #92dafe;
    position: absolute;
    left: 0;
	top: 0;
	border-radius: 0px 50px 50px 50px;
	border: 2px solid #74b0ce;
	box-shadow: inset 7px -15px 16px 0px rgb(87, 171, 212);
}
#waves-menu-btn > img {
	z-index: 1;
	position: relative;
	width: 65px;
	top: 50%;
	transform: translateY(-50%);
}
#waves-menu-btn {
	position: absolute;
	width: 65px;
	z-index: 1;
	left: 0;
	top: 0;
	height:60px;
	padding: 15px;
	cursor: pointer;
	transition: transform .2s ease, box-shadow .3s ease;
}
#waves-menu-btn.hide {
	transform: translateX(-150%) translateY(-50%);
}
/* #waves-menu-btn:hover {
	transform: scale(1.2);
} */
  
#vertical-nav li:hover {
	transform: translateX(50%);
	opacity: 1;
}
  
#vertical-nav li {
	position: relative;
	height: calc(100% / 7);
	line-height: calc((100vh - 130px) / 7);
	transition: transform 1s cubic-bezier(0.62, 1.84, 1, 0.85), opacity .3s;
	background-size:auto 100%;
	background-repeat: no-repeat;
	background-position: center;
	font-size: 9vw;
	/* font-size: 1.5em; */
	word-wrap: normal;
	color: #FFC107;
	opacity: .8;
	background-position: right;
}
  
#vertical-nav li a {
	width: 100%;
	height: 100%;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	pointer-events: none;
	
    text-indent: 100%;
    overflow: hidden;
}
#vertical-nav.show li a {
	width: 130vw;

}
  
#vertical-nav li.active a, #impressum.active a {
	pointer-events: all;
	z-index: 2;
}

#QuizLink {
	position: fixed;
	top: 15px;
	right: 15px;
	/* width: 15vw; */
	z-index: 4;
	transform: scaleX(-1);
	transition: transform 1s;
}
#QuizLink img {
	/* width: 100%; */
	width: 70px;
}
#QuizLink a {
	text-indent: 100%;
	overflow: hidden;
    line-height: 0; 
    font-size: 0;
    color: transparent; 
}
#QuizLink.hide {
	transform: translateX(150%) translateY(-150%) rotate(180deg) scaleX(-1);
}
#impressum {
	position: fixed;
	bottom: 105px;
	right: -50px;
	z-index: 10;
	transition: right 0.5s, bottom 1s;
  }
#impressum img {
	width: 150px;
	height: auto;
  }
  
  #impressum:hover {
	right: 0px;
  }
  #impressum a {
	width: 100%;
	height: 100%;
	pointer-events: none;
	text-indent: 100%;
	overflow: hidden;
    line-height: 0; 
    font-size: 0;
    color: transparent; 
}


/* Map */
#vertical-nav li:nth-of-type(1):after { content: attr(data-content); position: relative; opacity: 0;}
/* #vertical-nav li:nth-of-type(1):hover:after { opacity: 1; display: inline-block; transform: translateX(11%); pointer-events: auto;} */

/* Stone */
#vertical-nav li:nth-of-type(2):after { content: attr(data-content); position: relative; opacity: 0;}
/* #vertical-nav li:nth-of-type(2):hover:after { opacity: 1;  display: inline-block; transform: translateX(11%); pointer-events: auto;} */

/* Bernstein */
#vertical-nav li:nth-of-type(3):after { content: attr(data-content); position: relative; opacity: 0;}
/* #vertical-nav li:nth-of-type(3):hover:after { opacity: 1; display: inline-block; transform: translateX(11%); pointer-events: auto;} */

/* Fish */
#vertical-nav li:nth-of-type(4):after { content: attr(data-content); position: relative; opacity: 0;}
/* #vertical-nav li:nth-of-type(4):hover:after { opacity: 1; display: inline-block; transform: translateX(11%); pointer-events: auto;} */

/* Shell */
#vertical-nav li:nth-of-type(5):after { content: attr(data-content); position: relative; opacity: 0;}
/* #vertical-nav li:nth-of-type(5):hover:after { opacity: 1; display: inline-block; transform: translateX(11%); pointer-events: auto;} */

/* Boat */
#vertical-nav li:nth-of-type(6):after { content: attr(data-content); position: relative; opacity: 0;}
/* #vertical-nav li:nth-of-type(6):hover:after { opacity: 1; display: inline-block; transform: translateX(11%); pointer-events: auto;} */

/* netz */
#vertical-nav li:nth-of-type(7):after { content: attr(data-content); position: relative; opacity: 0;}
/* #vertical-nav li:nth-of-type(7):hover:after { opacity: 1; display: inline-block; transform: translateX(11%); pointer-events: auto;} */

#vertical-nav li:hover:after { 
	opacity: 1;
	display: inline-block;
	/* transform: translateX(calc(30vw + 20px)); */
	transform: translateX(190px);
	pointer-events: auto;
}
#vertical-nav.show li:after { 
	opacity: 1;
	display: inline-block;
	/* transform: translateX(calc(30vw + 20px)); */
	/* transform: translateX(190px); */
	transform: translateX(160px) translateY(-7px);
	pointer-events: auto;
}
#vertical-nav li:after {
	padding-left: 30px;
	left: 0;
	text-align: left;
	top: 0%;
	white-space: nowrap;
	/* width: 400PX;  */
	display: inline-block;
	/* transition: all 1s; */
	transform: translateX(0);
	transition: opacity 1s, transform 1s, width 1s;
	pointer-events: none;
	z-index: 1;	
	
	line-height: calc((100vh - 130px) / 7);

	font-family: 'IM Fell English SC';
	/* font-family: 'Fira Sans', sans-serif; */
    font-style: normal;
    color: #FFC107;
    text-shadow: 2px 2px 10px black, -1px -1px 10px black;
}


#vertical-nav li > span > img {
	width: 1em;
}

.Page {
	/* -webkit-transition: -webkit-filter 1000ms linear; */
	transition: filter 1000ms linear;
}

.blur {
	filter: blur(5px) grayscale(10%);
	/* -webkit-transition: -webkit-filter 1000ms linear; */
	transition: filter 1000ms linear;
}

/* Impressum */




/* custom font */
@import url(https://fonts.googleapis.com/css?family=Arvo:700);

/* #vertical-nav li:after { */
	/* color: #e0dfdc;
	background-color: #333;
	letter-spacing: .1em;
	text-shadow: 0 0 0 #FFC107,
				-1px -1px 0 #2e2e2e,
				-1px 1px 0 #2e2e2e,
				0 1px 0 #2e2e2e,
				0 -2px 0 #2c2c2c,
				0 2px 0 #2c2c2c,
				1px 1px 0 #2e2e2e,
				2px 2px 0 #2c2c2c,
				3px 3px 0 #2a2a2a,
				4px 4px 0 #282828,
				5px 5px 0 #262626,
				6px 6px 0 #242424,
				7px 7px 0 #222,
				8px 8px 0 #202020,
				9px 9px 0 #1e1e1e,
				10px 10px 0 #1c1c1c,
				11px 11px 0 #1a1a1a,
				12px 12px 0 #181818,
				13px 13px 0 #161616,
				14px 14px 0 #141414,
				15px 15px 0 #121212,
				22px 22px 30px rgba(0, 0, 0, 0.9); */

	/* font-family: "Avant Garde", Avantgarde, "Century Gothic", CenturyGothic, "AppleGothic", sans-serif; */
	/* font-size: 92px;
	padding: 80px 50px; */
	/* text-align: center; */
	/* text-transform: uppercase;
	text-rendering: optimizeLegibility; */
/* } */


/* breakpoints */
/* phone only */
@media (max-width: 599px) {
	#impressum {
		bottom: 5px;
	}
}
/* tablet portrait only */
@media (min-width: 600px) and (max-width: 899px) {
	#impressum {
		bottom: 5px;
	}
}
/* tablet portrait up */
@media (min-width: 600px) {
	/* #vertical-nav { width: 20vh; } */
	/* #vertical-nav li { font-size: 2em; } */
	
	#vertical-nav li:after {
		margin-left: 10px;
	}
	/* #vertical-nav li:nth-of-type(1):hover:after { transform: translateX(40vw); }
	#vertical-nav li:nth-of-type(2):hover:after { transform: translateX(40vw); }
	#vertical-nav li:nth-of-type(3):hover:after { transform: translateX(40vw); }
	#vertical-nav li:nth-of-type(4):hover:after { transform: translateX(40vw); }
	#vertical-nav li:nth-of-type(5):hover:after { transform: translateX(40vw); }
	#vertical-nav li:nth-of-type(6):hover:after { transform: translateX(40vw); }
	#vertical-nav li:nth-of-type(7):hover:after { transform: translateX(40vw); } */
	
	
	#impressum {
		bottom: 105px;
	}
}
/* tablet landscape only */
@media (min-width: 900px) and (max-width: 1199px) {
}
/* tablet landscape up */
@media (min-width: 900px) {
	#impressum {
		bottom: 105px;
	}
	#vertical-nav li {
		font-size: 80px;
	}
}
/* desktop up */
/* @media (min-width: 1000px) {
	#vertical-nav li {
		font-size: 80px;
	}
} */
/* desktop only */
@media (min-width: 1200px) and (max-width: 1799px) {
}
/* desktop up */
@media (min-width: 1200px) {
	
}
/* big desktop up */
@media (min-width: 1800px) {
}


/* breakpoints */
/* phone only */
@media (max-width: 599px) {
	.crab {
		left: 50% !important;
		transform: translateX(-50%) !important;
	}
	#waves-menu-btn {
		transform: scale(0.7) translateX(-15%) translateY(-15%);
	}
	#QuizLink {
		top: 5px;
		right: 5px;
	}
}
/* tablet portrait only */
@media (min-width: 600px) and (max-width: 899px) {
	.crab {
		left: 50%;
		transform: translateX(-50%);
	}
}
/* tablet portrait up */
@media (min-width: 600px) {
	.crab {
		width: 150px;
		height: 150px;
		left: 50%;
		transform: translateX(-50%);
	}
}
/* tablet landscape only */
@media (min-width: 900px) and (max-width: 1199px) {
	/* go to right side */

}
/* tablet landscape up */
@media (min-width: 900px) {
	.crab {
		transform: rotate(-16deg) translateX(-50%) !important;
		transform-origin: center;
		left: 100% !important;
		transform-origin: bottom;
	}
}
/* desktop only */
@media (min-width: 1200px) and (max-width: 1799px) {
}
/* desktop up */
@media (min-width: 1200px) {
}
/* big desktop up */
@media (min-width: 1800px) {
}
