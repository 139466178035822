.crab {
	width: 100px;
	height: 100px;
	transform: rotate(-35deg) translateX(-45%) translateY(-15%) !important;
	transform-origin: center;
	left: 96% !important;
	transform-origin: bottom;
	z-index: 1;
	transition: all 1s;
	position: fixed;
	bottom: 0;
}
.crab img {
	filter: drop-shadow(0 0 2px #000);
	padding: 4px;
}
.crab.hide {
	transform: translateX(-50%) translateY(100%) !important;
	transform-origin: center;
	animation: hide 4s ease;
}
.crab.hideRight {
	transform-origin: center;
	transform: rotate(-7deg) translateX(35vw) !important;
}
.crab.toTop {
	/* transform-origin: center;
	transform: rotate(-7deg) translateX(35vw) !important; */
	animation: toTop 7s ease-out;
	transform: translateX(-30vw) translateY(-102vh)  rotate(170deg) !important;
}



@keyframes hide {
	0% {transform: translateX(-50%) translateY(0%);}
	50% {transform: rotate(7deg) translateX(-50%) translateY(40%);}
	100% {transform: translateX(-50%) translateY(100%);}
}
@keyframes toTop {
	0% {transform: translateX(-45%) translateY(0);}
	/* 50% {transform: translateX(-5vw) translateY(-50vh) rotate(-100deg);} */
	100% {transform: translateX(-30vw) translateY(-102vh)  rotate(170deg);}
}
@keyframes wave_right_arm {
	0% {transform: rotate(0deg);}
	50% {transform: rotate(7deg);}
	100% {transform: rotate(0deg);}
}
@keyframes wave_left_arm {
	0% {transform: rotate(0deg);}
	50% {transform: rotate(-7deg);}
	100% {transform: rotate(0deg);}
}




/* breakpoints */
/* phone only */
@media (max-width: 599px) {
	.crab {
		left: 50% !important;
		transform: translateX(-50%) !important;
	}
}
/* tablet portrait only */
@media (min-width: 600px) and (max-width: 899px) {
	.crab {
		left: 50%;
		transform: translateX(-50%);
	}
}
/* tablet portrait up */
@media (min-width: 600px) {
	.crab {
		width: 150px;
		height: 150px;
		left: 50%;
		transform: translateX(-50%);
	}
}
/* tablet landscape only */
@media (min-width: 900px) and (max-width: 1199px) {
	/* go to right side */

}
/* tablet landscape up */
@media (min-width: 900px) {
	.crab {
		transform: rotate(-16deg) translateX(-50%) !important;
		transform-origin: center;
		left: 96% !important;
		transform-origin: bottom;
	}
}
/* desktop only */
@media (min-width: 1200px) and (max-width: 1799px) {
}
/* desktop up */
@media (min-width: 1200px) {
}
/* big desktop up */
@media (min-width: 1800px) {
}
