.GedserQuiz .card {
	background: #e1ffc7b8;
	transition: all .5s;
}
.GedserQuiz .result-answer > button {
	transition: all .5s;
}
.GedserQuiz .answer-container > div > button {
	transition: all .2s;
	background: #ffffff6b;
}