.iframe-tv {
	width: 80vw;
	height: 54vw;
	position: relative;
	margin: 15px auto;
	overflow: hidden;
	border-radius: 5vw;
	border: 5px ridge #3d2925;
}

.iframe-tv .frame-overlay, .iframe-tv .screen-overlay, .iframe-tv .screen-background {
	width: 100%;
	height: 100%;
	left: 0;
	top:0;
	position: absolute;
	pointer-events: none;
}
.iframe-tv .screen-overlay {
	opacity: .5;
}

.iframe-tv iframe {
	height: 100%;
	width: 79%;
	left: 1.6%;
	position: absolute;
}